
















































































import {
  defineComponent,
  ref,
  computed,
  inject,
  watch
} from "@/composition-api";
import Colorpicker from "@/components/AHSaveSearchDialog/parts/Colorpicker.vue";
import UiButton from "@/components/ui/UiButton.vue";
import UiCard from "@/components/ui/UiCard.vue";
import UiInput from "@dpa-id-components/ui-input";
import UiToggle from "@/components/ui/UiToggle.vue";
import UiIconArrowLeft from "@/assets/vectors/icons/arrow-left.svg?inline";
import UiIconDelete from "@/assets/vectors/icons/delete.svg?inline";
import store from "@/store";
import router from "@/router";
import useBreakpoints from "@/composition/useBreakpoints";
import UiErrorMessage from "@/components/ui/UiErrorMessage.vue";
import useFilterNameValidator from "@/composition/useFilterNameValidator";

export default defineComponent({
  components: {
    Colorpicker,
    UiButton,
    UiCard,
    UiIconArrowLeft,
    UiIconDelete,
    UiInput,
    UiToggle,
    UiErrorMessage
  },
  setup() {
    const eventBus: Vue = inject("eventBus");
    const { isMobile } = useBreakpoints();

    const filterId = computed(() => store.state.route.params.filterId);
    const filter = computed(() =>
      store.getters["filters/getFilterById"](filterId.value)
    );

    const filterName = ref(filter.value.name as string);
    const filterColor = ref(filter.value.color);
    const filterIsVisible = ref(filter.value.isVisible);
    const filterEmailNotificationIsEnabled = ref(
      filter.value.isNotificationEnabled
    );

    watch(
      filter,
      () => {
        filterName.value = filter.value.name;
        filterColor.value = filter.value.color;
        filterIsVisible.value = filter.value.isVisible;
        filterEmailNotificationIsEnabled.value =
          filter.value.isNotificationEnabled;
      },
      { deep: true }
    );

    const authJWT = store.state.auth.token;

    const updatedSearchFilter = computed(() => ({
      ...filter.value,
      id: filter.value?.id,
      color: filterColor.value,
      name: filterName.value,
      isVisible: filterIsVisible.value,
      isNotificationEnabled: filterEmailNotificationIsEnabled.value
    }));

    const saveUpdatedFilter = () => {
      store.dispatch("filters/updateFilter", {
        filter: updatedSearchFilter.value,
        authJWT
      });

      eventBus.$emit("show", {
        title: "Änderungen gespeichert",
        icon: "checkmark"
      });
    };

    const deleteSavedSearchFilter = () => {
      store.dispatch("filters/deleteFilter", {
        filter: store.getters["filters/getFilterById"](filter.value.id),
        authJWT
      });

      router.push({
        name: "settings"
      });

      eventBus.$emit("show", {
        title: `"${filterName.value}" wurde gelöscht`,
        icon: "delete",
        action() {
          store.dispatch("filters/undo");
        },
        actionTitle: "Rückgängig"
      });
    };

    const { isFilterNameValid } = useFilterNameValidator();
    const currentFilterNameInvalid = ref(false);
    watch(filterName, filterName => {
      currentFilterNameInvalid.value = isFilterNameValid(filterName);
    });

    return {
      filterName,
      filterColor,
      filterIsVisible,
      filterEmailNotificationIsEnabled,
      updatedSearchFilter,
      saveUpdatedFilter,
      deleteSavedSearchFilter,
      isMobile,
      currentFilterNameInvalid
    };
  }
});
